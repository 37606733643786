
import { defineComponent, watch } from 'vue'
import { useInvestigationForm, useFormProgress } from '@/use/cluster-form'
import { usePatientAnswerState } from '@/use/save-restore'

import AnswerForm from '@/components/AnswerForm.vue'
import ClusterIngress from '@/components/ClusterIngress.vue'
import FormSummary from '@/components/FormSummary.vue'
import { useIdentityGuard } from '@/use/login'
import { computedParam, computedQuery } from '@/use/route'
import { useRouter } from 'vue-router/composables'
import { Config } from '@/lib/di/config'
import { useFakeFillForm } from '@/use/fake-fill'

export default defineComponent({
  name: 'FormView',
  components: { AnswerForm, FormSummary, ClusterIngress },
  setup () {
    const id = computedParam('id')
    const { isDev } = Config.get()
    const done = computedQuery('done')

    const { load, save } = usePatientAnswerState(id)

    const { data: form, ...formrest } = useInvestigationForm(id)
    const formState = useFormProgress(form, load, save, done)

    const router = useRouter()
    function complete () {
      // @TODO Lock/complete answers
      router.push({ name: 'Thanks', params: { id: id.value } })
        .catch(err => console.error('Failed to navigate', err))
    }

    watch(formrest.error, (e) => {
      if (e && e.status === 401) {
        router.push({ name: 'Welcome', params: { id: id.value } })
          .catch(err => console.error('Failed to navigate', err))
      }
    })

    useIdentityGuard({ name: 'Welcome', params: { id: id.value } })

    function editAnswer ({ cluster, dimension }: {cluster: string, dimension: string}) {
      formState.goTo(cluster, dimension)
    }

    function goToPesonalQuestions () {
      router.push({ name: 'PersonalQuestions', params: { id: id.value } })
        .catch(err => console.error('Failed to navigate', err))
    }

    const { fill: fillForm } = useFakeFillForm(formState.data, form)

    function fill () {
      fillForm()
      formState.goDone(true)
    }

    return {
      id,
      done,
      complete,
      editAnswer,
      goToPesonalQuestions,
      form,
      isDev,
      fill,
      ...formrest,
      ...formState
    }
  },
  metaInfo: {
    title: 'Besvara'
  }
})
