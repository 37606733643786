import { titleCase } from '@/lib/formatter'
import { computed, inject, onMounted, onUnmounted, provide, ref, watch } from 'vue'
import { IdentityLoginApi, LoginStatusFailed, LoginStatusFailedLoggedInEnum, LoginStatusSuccess, LoginStatusSuccessTypeEnum } from '../api/index'
import { useIntervalFn } from '@vueuse/core'
import { useRouter } from 'vue-router/composables'
import { useVisibility } from './guard'
import { RawLocation } from 'vue-router'

export const Login = Symbol('LoginStatus')
export interface LoginError { status: number, statusText: string, url: string }

function useLogin (api: IdentityLoginApi) {

  const status = ref<LoginStatusSuccess | LoginStatusFailed>({ loggedIn: LoginStatusFailedLoggedInEnum.False })
  const error = ref<LoginError | null>(null)
  const loaded = ref<boolean>(false)

  async function reload (): Promise<boolean> {
    try {
      const res = await api.getLogin()
      status.value = res as unknown as LoginStatusSuccess
      error.value = null
      return true
    } catch (err) {
      const { status: statusCode, statusText, url } = err as Response
      error.value = { status: statusCode, statusText, url }
      status.value = { loggedIn: LoginStatusFailedLoggedInEnum.False }
      return false
    } finally {
      loaded.value = true
    }
  }

  async function logout () {
    try {
      await api.doLogout()
      await reload()
    } catch (err) {
      const { status: statusCode, statusText, url } = err as Response
      error.value = { status: statusCode, statusText, url }
    }
  }
  const isIdentity = computed(
    () => status.value.loggedIn && status.value.type !== LoginStatusSuccessTypeEnum.Login
  )
  const isLogin = computed(
    () => status.value.loggedIn && status.value.type === LoginStatusSuccessTypeEnum.Login
  )
  const identity = computed(
    () => status.value.loggedIn && status.value.type === 'identity' ? status.value as LoginStatusSuccess : null
  )
  const login = computed(
    () => isLogin.value ? status.value as LoginStatusSuccess : null
  )
  const name = computed(() => login.value?.name ?? identity.value?.name ?? '')
  const prettyName = computed(() => titleCase(name.value))

  onMounted(async () => await reload())

  return { status, error, reload, logout, loaded, isIdentity, isLogin, login, identity, prettyName }
}

export function provideGlobalLogin (ident: IdentityLoginApi) {
  const login = useLogin(ident)
  provide(Login, login)
  return login
}

export function useGlobalLogin () {

  const login = inject<ReturnType<typeof useLogin>>(Login)

  if (!login) {
    throw new Error('provideGlobalLogin() has not been mounted properly')
  }

  const { reload, loaded, ...rest } = login

  useVisibility({
    onVisible: () => {
      reload()
        .catch(err => console.error('Failed to reload() in useVisibility()', err))
    }
  })

  const { pause: pauseLoginUpdates, resume: resumeLoginUpdates } = useIntervalFn(() => {
    reload()
      .catch(err => console.error('Failed to reload() in useInterval()', err))
  }, 5 * 60 * 1000)

  onMounted(() => {
    resumeLoginUpdates()
    if (!loaded.value) {
      reload()
        .catch(err => console.error('Failed to reload() in onMounted()', err))
    }
  })

  onUnmounted(() => {
    pauseLoginUpdates()
  })

  return { reload, ...rest }
}

type RoleString = 'Superadmin' | 'Admin' | 'Profession'
type RoleFilter = RoleString | RoleString[]

interface Route { name: string }

export function useLoginGuard (roles: RoleFilter = ['Admin', 'Profession'], route?: Route | RawLocation) {
  const router = useRouter()
  const login = useGlobalLogin()

  function handleChange (status: unknown) {
    if (!login.isLogin.value) {
      console.log('User is trying to view page when not logged in, redirecting...', status)
      return router.push(route ?? { name: 'Login', query: { go: router.currentRoute.fullPath } })
    }
    if (roles && !roles.includes((login.login.value?.role ?? '') as unknown as RoleString)) {
      console.log('User is trying to view page but', login.login.value?.role, 'is not in', roles, 'redirecting...')
      return router.push(route ?? { name: 'Login', query: { go: router.currentRoute.fullPath } })
    }
  }

  watch(login.status, handleChange)

  return login
}

export function useIdentityGuard (route?: Route | RawLocation) {
  const router = useRouter()
  const login = useGlobalLogin()

  watch(login.isIdentity, (isIdentity) => {
    if (!isIdentity) {
      console.log('User is trying to view page when not logged in, redirecting...', login.status.value)
      router.push(route ?? { name: 'Login', query: { go: router.currentRoute.fullPath } })
        .catch((err: any) => console.error('Failed to navigate', err))
    }
  })

  return login
}
