import { inject, InjectionKey, provide, reactive, toRefs, UnwrapRef } from 'vue'

export const ConfigComponent: InjectionKey<UnwrapRef<ConfigType>> = Symbol('config')

export interface ConfigType {
  env: 'unset' | 'dev' | 'debug' | 'pilot';
  isDebug: boolean;
  isDev: boolean;
  isPilot: boolean;
}

export class Config {
  static init () {
    const env = process.env
    let appEnv = env.VUE_APP_ENV as ConfigType['env']

    if (!appEnv || appEnv === 'unset') {
      if (location.hostname === 'dev.e-admin.se') {
        appEnv = 'dev'
      }
      if (location.hostname === 'pilot.e-admin.se') {
        appEnv = 'pilot'
      }
      if (location.hostname === 'localhost') {
        appEnv = 'debug'
      }
    }

    const config = reactive<ConfigType>({
      env: appEnv,
      isDebug: appEnv === 'debug',
      isDev: appEnv === 'dev' || appEnv === 'debug',
      isPilot: appEnv === 'pilot'
    })

    provide(ConfigComponent, config)

    return { config }
  }

  static get () {
    const config = inject(ConfigComponent, reactive<ConfigType>({} as ConfigType))
    return { ...toRefs(config) }
  }
}
