
import { computed, defineComponent, onMounted, ref } from 'vue'

import LoginForm from '@/components/form/LoginForm.vue'
import CenteredLoading from '@/components/common/CenteredLoading.vue'
import BackendErrorMessage from '@/components/common/BackendErrorMessage.vue'

import { AuthService, LoginStatusSuccess } from '../api/index'
import { Api } from '@/lib/di/api'
import { BackendError, errorFromResponse } from '@/use/loadable'

import { Config } from '@/lib/di/config'
import { useGlobalLogin } from '@/use/login'
import { str } from '@/lib/types'
import { computedQuery } from '@/use/route'
import { z } from 'zod'
import { useRoute, useRouter } from 'vue-router/composables'

export default defineComponent({
  components: { LoginForm, CenteredLoading, BackendErrorMessage },
  setup () {
    const grandidsession = computedQuery('grandidsession', z.string().optional())
    const go = computedQuery('go', z.string().optional())

    const { status, login, reload } = useGlobalLogin()
    const api = Api.identlogin()
    const loadingText = ref<string>()
    const error = ref<'' | 'no_such_user' | 'unknown'>('')
    const errorValue = ref<BackendError>()

    const router = useRouter()
    function reset () {
      error.value = ''
      errorValue.value = undefined
      router.replace({ params: {} })
        .catch(err => console.error('Failed to reset query params', err))
    }

    async function redirect (role: string) {
      if (go.value) {
        const loc = new URL(go.value, document.location.toString())
        const isSameOrigin = loc.origin === document.location.origin
        if (isSameOrigin && !go.value.includes('/login')) {
          document.location = loc.toString() as unknown as Location
        }
      } else if (role === 'Superadmin') {
        await router.push('/organisations')
      } else {
        await router.push('/patients')
      }
    }

    onMounted(async () => {
      await reload()
      if (login.value) {
        await redirect(login.value.role)
      }
    })

    async function onComplete (event: LoginStatusSuccess) {
      status.value = event
      await reload()
      await redirect(event.role)
    }

    function callbackUrl (name: AuthService): string {
      const kws = [
        ['authservice', name]
      ]
      if (go.value) {
        kws.push(['go', str(go.value)])
      }
      const params = kws.map(k => k.join('=')).join('&')
      const url = new URL(document.location.toString())
      url.search = '?' + params
      return url.toString()
    }

    async function start (namestr: string) {
      const name = namestr as AuthService
      loadingText.value = 'Startar inloggning...'
      try {
        const res = await api.startIDPAuthentication({
          startIDPAuthentication: {
            callbackUrl: callbackUrl(name),
            customerURL: document.location.toString()
          },
          authservice: name
        })
        if (res.errorObject) {
          loadingText.value = res.errorObject.message
        } else {
          document.location = res.redirectUrl as unknown as Location
        }
      } catch (err: any) {
        console.error('Failed to start IDP authentication', err)
        error.value = 'unknown'
        const resp = await errorFromResponse(err)
        loadingText.value = undefined
        errorValue.value = resp
      }
    }

    onMounted(async () => {
      if (grandidsession.value) {
        authenticate()
          .catch(err => console.error('Failed to authenticate', err))
      }
    })

    const route = useRoute()
    const loginUsernameType = computed(() => {
      const { authservice } = route.query as { authservice: string }
      if (authservice && authservice === 'bankid') return 'personnummer'
      if (authservice && authservice === 'netidaccess') return 'HSA ID / Personnummer'
      if (authservice && authservice === 'netidenterprise') return 'HSA ID / Personnummer'
      return ''
    })

    async function authenticate () {
      loadingText.value = 'Loggar in'
      const { grandidsession, authservice } = route.query as { grandidsession: string, authservice: string }

      try {
        const res = await api.completeIDPAuthentication({
          completeIDPAuthentication: { grandidsession },
          authservice: authservice as unknown as AuthService
        })
        loadingText.value = `Välkommen ${res.user.name}!`
        await reload()
        const l = login.value
        if (l?.loggedIn) {
          setTimeout(() => {
            redirect(l.role)
              .catch(err => console.error('Failed to redriect', err))
          }, 500)
        }
      } catch (err) {
        loadingText.value = undefined
        const resp = await errorFromResponse(err)
        if (resp.status === 403 && resp.error === 'AuthError') {
          error.value = 'no_such_user'
          errorValue.value = resp
        } else {
          error.value = 'unknown'
          errorValue.value = resp
        }
      }
    }
    const { isDev } = Config.get()
    return { onComplete, start, reset, loadingText, loginUsernameType, error, errorValue, isDev }
  },
  metaInfo: {
    title: 'Logga in'
  }
})
