
import { defineComponent } from 'vue'
import { usePlainForm, useFormProgressFromLocalState } from '@/use/cluster-form'

import AnswerForm from '@/components/AnswerForm.vue'
import ClusterIngress from '@/components/ClusterIngress.vue'
import ClusterReview from '@/components/ClusterReview.vue'

import FormRadarChart from '@/components/chart/FormRadarChart.vue'
import FormBarChart from '@/components/chart/FormBarChart.vue'

import BackendErrorMessage from '@/components/common/BackendErrorMessage.vue'
import { useFakeFillForm } from '@/use/fake-fill'
import { Config } from '@/lib/di/config'
import { computedQuery } from '@/use/route'
import { useRouter } from 'vue-router/composables'

export default defineComponent({
  name: 'FormView',
  components: { AnswerForm, ClusterIngress, ClusterReview, BackendErrorMessage, FormRadarChart, FormBarChart },
  setup () {
    const done = computedQuery('done')
    const { isDev } = Config.get()

    const { data: form, ...formrest } = usePlainForm()
    const formState = useFormProgressFromLocalState(form, done)
    const { fill: fillForm } = useFakeFillForm(formState.data, form)

    function fill () {
      fillForm()
      formState.goDone(true)
    }

    const router = useRouter()

    function goToReview () {
      router.push({ name: 'OpenReview' })
        .catch((err: Error) => console.error('Failed to navigate', err))
    }

    function goToWelcome () {
      router.push({ name: 'OpenWelcome' })
        .catch((err: Error) => console.error('Failed to navigate', err))
    }

    function editAnswer ({ cluster, dimension }: {cluster: string, dimension: string}) {
      formState.goTo(cluster, dimension)
    }

    return {
      goToReview,
      goToWelcome,
      editAnswer,
      form,
      fill,
      done,
      isDev,
      ...formrest,
      ...formState
    }
  },
  metaInfo: {
    title: 'Besvara'
  }
})
