
import {
  PatientCreateFormModel, PatientCreateGroupState, PatientCreateInputState, PatientCreateFeedback,
  PatientEditFormModel, PatientEditGroupState, PatientEditInputState, PatientEditFeedback
} from '@/use/patients'
import { defineComponent, PropType } from 'vue'
import { ssnformat } from '@/lib/formatter'
import { Config } from '@/lib/di/config'

export default defineComponent({
  name: 'PatientForm',
  components: {},
  props: {
    inputstate: { type: Object as PropType<PatientCreateInputState | PatientEditInputState>, required: true },
    groupstate: { type: Object as PropType<PatientCreateGroupState | PatientEditGroupState>, required: true },
    feedback: { type: Object as PropType<PatientCreateFeedback | PatientEditFeedback>, required: true },
    form: { type: Object as PropType<PatientCreateFormModel | PatientEditFormModel>, required: true },
    error: { type: [String, Object], required: false },
    actionLabel: { type: String, required: true }
  },
  setup () {
    const { isDev } = Config.get()
    return { ssnformat, isDev }
  }
})
