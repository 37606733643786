
import { defineComponent } from 'vue'
import { useCreatePatient } from '@/use/patients'
import { useLoginGuard } from '@/use/login'
import { Config } from '@/lib/di/config'
import { useRouter } from 'vue-router/composables'

import PatientForm from '@/components/form/PatientForm.vue'

export default defineComponent({
  name: 'PatientCreate',
  components: { PatientForm },
  setup () {
    const { submit, feedback, inputstate, groupstate, form, error, fakeFill } = useCreatePatient(onSuccess)
    const { isDev } = Config.get()
    useLoginGuard()

    const router = useRouter()
    function onSuccess (id: string) {
      router.push({ name: 'Patient', params: { id } })
        .catch(err => console.error('Failed to navigate', err))
    }

    return {
      isDev,
      fakeFill,
      submit, form, error, feedback, inputstate, groupstate
    }
  },
  metaInfo: {
    title: 'Skapa patient'
  }
})
